import * as React from "react"
import { Link } from "gatsby"
import Layout from "../page-components/layout/layout";
import { useEffect } from "react";


// markup
const NotFoundPage = () => {

  useEffect(
      () => {
        window.location = "/";
      },
      []
  );

  return (
    <Layout>

      <div className="container py-5">
        <div className="row">
          <div className="col-12">

            <h2>Seite konnte nicht gefunden werden!</h2>

            <p>
              Sie werden automatisch auf die <Link to={"/"}>Startseite</Link> weitergeleitet
            </p>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage
